<template>
  <section id="zoom-user-settings" class="route-content">
    <loader v-if="loading" :loading-message="loadingMessage" />

    <button @click.prevent="listZoomUsers" :disabled="loading">
      <i
        class="fas"
        :class="{
          'fa-redo-alt': users.length,
          'fa-cloud-download-alt': !users.length
        }"
      />
      {{ users.length ? "Reload" : "Fetch" }} Zoom Users
    </button>

    <!-- Users List -->
    <zoom-user-list-item header-item />
    <zoom-user-list-item v-for="z in users" :key="z.id" :user="z" />
  </section>
</template>

<script>
import { getZoomUsers } from "../models/zoom";
import Loader from "./Loader.vue";
import PermissionsMixin from "./mixins/permissions.mixin";
import ZoomUserListItem from "./ZoomUserListItem.vue";

export default {
  name: "ZoomUserSettings",

  mixins: [PermissionsMixin],

  components: { Loader, ZoomUserListItem },

  data: () => ({
    page_count: 0,
    page_number: 0,
    page_size: 0,
    total_records: 0,
    users: []
  }),

  methods: {
    async listZoomUsers() {
      this.startLoading("Fetching Zoom Users ... ");

      const res = await getZoomUsers();
      this.page_count = res.page_count;
      this.page_number = res.page_number;
      this.page_size = res.page_size;
      this.total_records = res.total_records;
      this.users = res.users;

      this.stopLoading();
    }
  }
};
</script>
